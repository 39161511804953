import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalStyled, { CloseButton } from './Modal.styled'
import Icon from './Icon'

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
  }

  static defaultProps = {
    onRequestClose: () => {},
    isOpen: false,
  }

  render() {
    const { children, onRequestClose, ...other } = this.props
    return (
      <ModalStyled onRequestClose={onRequestClose} {...other}>
        <div style={{ position: 'relative' }}>
          <CloseButton onClick={onRequestClose}>
            <Icon icon="times" size="2x" />
          </CloseButton>
          {children}
        </div>
      </ModalStyled>
    )
  }
}

export default Modal
