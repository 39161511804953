import React from 'react'
import styled, { css } from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'

import { Link } from 'gatsby'

import ReactFlagsSelect from 'react-flags-select'
import 'react-flags-select/css/react-flags-select.css'
import { BP_LARGE } from '../../styles/theme'

const Container = styled.div`
  background-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 15;
  transition: background-color 300ms ease-in;
  ${props =>
    props.color &&
    css`
      background-color: ${props.theme.primary};
      /* box-shadow: var(--shadow-dark); */
    `};
`

const LanguageBar = styled.div`
  display: flex;
  width: 100%;
  color: #fff;
  background-color: transparent;
  height: 65px;
  transition: background-color 300ms ease-in;
  ${props =>
    props.color &&
    css`
      background-color: rgba(91, 160, 252);
      /* box-shadow: var(--shadow-dark); */
    `};
`

const SelectLabel = styled.div`
  padding: 0.5rem 3rem;
  & p {
    margin: 0;
  }
`

const LanguageList = styled.ul`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding-right: 3rem;
  list-style: none;
`

const LanguageItem = styled(Link)`
  color: #fff;
  font-weight: 400;
  width: 150px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 300ms ease-in;
  & p {
    margin: 0;
  }
  & :hover {
    background: rgba(0, 0, 0, 0.15);
  }
`

const ContainerMenu = styled.div`
  display: none;
  height: 52px;
  .bm-burger-button {
    position: fixed;
    width: 28px;
    height: 22px;
    left: 16px;
    top: 16px;
  }
  .bm-burger-bars {
    background: white;
    height: 20%;
    border-radius: 10px;
  }
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  .bm-cross {
    background: #bdc3c7;
  }
  .bm-menu {
    background: ${props => props.theme.primary};
    padding: 1em 1.5em 0;
    font-size: 1rem;
  }
  .bm-morph-shape {
    fill: ${props => props.theme.primary};
  }
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  .bm-item {
    display: inline-block;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  @media only screen and (max-width: ${BP_LARGE}) {
    display: inherit;
  }
`

const SectionHeader = styled(Row)`
  @media only screen and (max-width: ${BP_LARGE}) {
    display: none;
  }
`

const Content = styled.div`
  margin: 0 auto;
  padding: 1.45rem 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LinkOut = styled.a`
  text-decoration: none;
  color: ${props => props.theme.textLight};
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.green};
    `}
  &:hover {
    color: ${props => props.theme.green};
  }
  @media only screen and (max-width: ${BP_LARGE}) {
    display: block;
    padding: 1rem 0;
    width: fit-content;
  }
`

const LinkIn = styled(props => (
  <Link {...props} activeStyle={{ color: '#00D000' }} />
))`
  text-decoration: none;
  color: ${props => props.theme.textLight};
  font-size: 16px;
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.green};
    `}
  &:hover {
    color: ${props => props.theme.green};
  }
  @media only screen and (max-width: ${BP_LARGE}) {
    display: block;
    width: fit-content;
    padding: 0.5rem 0;
  }
`

const LinkButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.textLight};
  font-family: 'Lato';
  font-weight: 300;
  font-size: 18px;
  border: none;
  padding: 1px 0 3px;
  &:hover {
    color: ${props => props.theme.green};
  }
  &:focus {
    outline: none;
  }
`

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${props => props.theme.primary};
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  min-width: 160px;
  z-index: 1;
  & > a {
    float: none;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
`

const Dropdown = styled.div`
  float: left;
  overflow: hidden;
  &:hover > ${DropdownContent} {
    display: block;
  }
  &:hover > ${LinkButton} {
    color: ${props => props.theme.green};
  }
  &:hover > ${LinkIn} {
    color: ${props => props.theme.green};
  }
`

const ReactFlagStyled = styled(ReactFlagsSelect)`
  .selected--flag--option {
    height: 25px;
  }
  .arrow-down {
    display: none;
  }
`

const ContainerLogo = styled.div`
  background-color: ${props => props.theme.primary};
  padding: 0.55rem 1rem;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
  @media only screen and (max-width: ${BP_LARGE}) {
    top: 52px;
  }
`
const ContainerIconMenuItem = styled.div`
  display: inline;
  text-align: center;
  margin-right: 1rem;
`

export {
  Container,
  ContainerMenu,
  SectionHeader,
  Content,
  LinkIn,
  LinkOut,
  LinkButton,
  ReactFlagStyled,
  DropdownContent,
  Dropdown,
  ContainerLogo,
  ContainerIconMenuItem,
  LanguageBar,
  SelectLabel,
  LanguageList,
  LanguageItem,
}
