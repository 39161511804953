import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ icon, size, style = {} }) => (
  <FontAwesomeIcon
    icon={icon}
    size={size}
    style={{ minWidth: '30px', ...style }}
  />
)

export default Icon
