export const isoCountries = {
  US: 'en',
  PT: 'pt',
  MX: 'es',
  CN: 'zh',
}
export const handleOpenMenu = pathname => {
  switch (pathname) {
    case '/mision/':
    case '/sms-mexico/':
    case '/medios/':
      return 'aboutUs'
    case '/contabilidad/':
    case '/impuestos/':
    case '/bpo/':
    case '/internacional/':
    case '/advisory/':
    case '/sector-financiero/':
    case '/legal/':
    case '/news/':
    case '/as-xpert/':
    case '/marketing-contenido-digital/':
    case '/technologies/':
    case '/omnium/':
    case '/health/':
    case '/formacion-personal/':
      return 'ourGroup'
    default:
      return null
  }
}
export const itemsMenu = [
  {
    key: 'home',
    linkTo: '/',
    icon: 'home',
    list: [
      {
        icon: '',
        linkTo: '',
      },
    ],
  },
  {
    key: 'aboutUs',
    linkTo: '/nosotros/',
    icon: 'building',
    submenu: true,
    list: [
      {
        key: 'mision',
        out: false,
        linkTo: '/mision/',
        // icon: 'chevron-right'
      },
      {
        key: 'sms',
        out: false,
        linkTo: '/sms-mexico/',
        // icon: 'chevron-right'
      },
      {
        key: 'philosophy',
        out: false,
        linkTo: '/philosophy/',
      },
      {
        key: 'medios',
        out: false,
        linkTo: '/medios/',
        // icon: 'chevron-right'
      },
      {
        key: 'last_years',
        out: false,
        linkTo: '/last-years/',
      },
    ],
  },
  {
    key: 'ourGroup',
    linkTo: '/nuestro-grupo/',
    icon: 'layer-group',
    submenu: true,
    list: [
      {
        key: 'advisory',
        out: false,
        linkTo: '/advisory/',
      },
      {
        key: 'as-xpert',
        out: false,
        linkTo: '/as-xpert/',
      },
      {
        key: 'bpo',
        out: false,
        linkTo: '/bpo/',
      },
      {
        key: 'contabilidad',
        out: false,
        linkTo: '/contabilidad/',
      },
      {
        key: 'formacion-profesional',
        out: false,
        linkTo: '/formacion-profesional/',
      },
      {
        key: 'fundacion',
        out: true,
        linkTo: 'https://fundacionascg.org/',
      },
      {
        key: 'health',
        out: false,
        linkTo: '/health-group/',
      },
      {
        key: 'impuestos',
        out: false,
        linkTo: '/impuestos/',
      },
      {
        key: 'internacional',
        out: false,
        linkTo: '/internacional/',
      },
      {
        key: 'legal',
        out: false,
        linkTo: '/legal-group/',
      },
      {
        key: 'marketing-contenido-digital',
        out: false,
        linkTo: '/marketing-contenido-digital/',
      },
      {
        key: 'news',
        out: false,
        linkTo: '/news/',
      },
      {
        key: 'omnium',
        out: false,
        linkTo: '/omnium/',
      },
      {
        key: 'sector-financiero',
        out: false,
        linkTo: '/sector-financiero/',
      },
      {
        key: 'sustentabilidad',
        out: false,
        linkTo: '/sustentabilidad/',
      },
      {
        key: 'technologies',
        out: false,
        linkTo: '/technologies/',
      },
    ],
  },
  {
    key: 'solutions',
    linkTo: '/#consulting-services',
    icon: 'concierge-bell',
    list: [
      {
        icon: '',
        linkTo: '',
      },
    ],
  },
  {
    key: 'desk',
    linkTo: '/international-desk',
    icon: 'globe-americas',
    list: [
      {
        icon: '',
        linkTo: '',
      },
    ],
  },
  {
    key: 'asDigital',
    linkTo: '',
    icon: 'mobile-alt',
    submenu: true,
    list: [
      {
        key: 'blog',
        out: true,
        linkTo: 'https://asnews.mx/',
      },
      {
        key: 'miPyme',
        out: true,
        linkTo: 'https://centromipyme.com/',
      },
      {
        key: 'formadora-de-heroes',
        out: true,
        linkTo: 'https://formadoradeheroes.org/',
      },
      {
        key: 'asXpert',
        out: true,
        linkTo: 'https://asxpert.com.mx/',
      },
      {
        key: 'asiViewer',
        out: true,
        linkTo: 'http://ascg.uberprototech.com/login',
      },
      {
        key: 'asiConnect',
        out: true,
        linkTo: 'https://asiconnect.com.mx/',
      },
      {
        key: 'zoho',
        out: true,
        linkTo: 'https://zoho.ascg.mx/',
      },
    ],
  },
  {
    key: 'directory',
    linkTo: '/nuestro-equipo/',
    icon: 'users',
  },
  {
    key: 'asiViewer',
    icon: 'comments',
    out: true,
    linkTo: 'http://ascg.uberprototech.com/login',
  },
  {
    key: 'contact',
    linkTo: '/contacto/',
    icon: 'user-circle',
  },
]
