import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import hex2rgba from '../../utils/hex2rgba'

import { BP_MEDIUM } from '../../styles/theme'

const ModalAdapter = ({ className, modalClassName, ...props }) => {
  return (
    <Modal
      ariaHideApp={false}
      className={modalClassName}
      portalClassName={className}
      {...props}
    />
  )
}

export default styled(ModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    position: absolute;
    z-index: 5000;
    top: 50%;
    left: 50%;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    right: auto;
    bottom: auto;
    /* margin-right: -50%; */

    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    /* padding: 2rem; */
    /* max-width: 700px; */
    /* width: fit-content; */
    min-width: 350px;
    @media only screen and (max-width: ${BP_MEDIUM}) {
      max-width: 90%;
    }
    &:focus {
      outline: none;
    }
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .Overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => hex2rgba('#000000', 0.4)};
  }
`
const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  border: none;
  background-color: transparent;
  color: ${props => hex2rgba(props.theme.backgroundLight, 0.8)};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.backgroundLight};
  }
  &:focus {
    outline: none;
  }
`
export { CloseButton }
