import React from 'react'
import PropTypes from 'prop-types'
import { InputContainer, TextAreaStyled } from './field.styled'
import { FormattedMessage } from 'react-intl'

export default class TextArea extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    order: PropTypes.array,
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    validation: PropTypes.array,
    options: PropTypes.array,
    errors: PropTypes.object,
  }

  static defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    name: 'input',
    label: 'Input',
    required: false,
  }

  handleOnChange = e => {
    const { name, value } = e.target
    const { onChange } = this.props

    onChange(value, name, {})
  }

  render() {
    const { props } = this
    return (
      <InputContainer color={props.color}>
        <div>
          <FormattedMessage id={`element.input_label.${props.name}`} />
          <span style={{ color: 'red' }}>{props.required && '*'}</span>
        </div>
        <TextAreaStyled
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          disabled={props.disabled}
        />
        {props.errors && props.errors[props.name] && (
          <span style={{ color: 'red', fontWeight: '300' }}>
            {props.errors[props.name]}
          </span>
        )}
      </InputContainer>
    )
  }
}
