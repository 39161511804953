import React, { Component } from 'react'
import { compose, withStateHandlers } from 'recompose'
import { ReactFlagStyled } from './Header.styled'
import { isoCountries } from './utils'

class SelectLanguage extends Component {
  parseCountry = () => {
    switch (this.props.lang) {
      case 'en':
        return 'US'
      case 'pt':
        return 'PT'
      case 'zh':
        return 'CN'
      default:
        return 'MX'
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      const country = this.parseCountry()
      this.refs.userFlag.updateSelected(country)
    }
  }
  render() {
    const { selectLanguage } = this.props
    return (
      <ReactFlagStyled
        ref="userFlag"
        defaultCountry="MX"
        countries={['US', 'PT', 'MX', 'CN']}
        customLabels={{
          US: 'Ingles',
          PT: 'Portugués',
          MX: 'Español',
          CN: '中文',
        }}
        showSelectedLabel={false}
        showOptionLabel={true}
        selectedSize={18}
        onSelect={countryCode => selectLanguage(isoCountries[countryCode])}
      />
    )
  }
}

const enhance = compose(
  withStateHandlers(props => ({ language: props.lang }), {
    selectLanguage: (values, { toggleLanguage }) => value => {
      toggleLanguage(value)
      return {
        language: value,
      }
    },
  })
)

export default enhance(SelectLanguage)
