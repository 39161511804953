import styled, { css } from 'styled-components'

const InputStyled = styled.input`
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  height: 36px;
  font-size: 1rem;
  margin-bottom: 5px;
  &:focus {
    outline: none !important;
    border: 1px solid ${props => props.theme.primary};
    box-shadow: 0 0 5px ${props => props.theme.primary};
  }
`
const TextAreaStyled = styled.textarea`
  max-width: 100%;
  font-size: 1rem;
  resize: vertical;
  min-height: 86px;
  max-height: 120px;
  border: none;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  &:focus {
    outline: none !important;
    border: 1px solid ${props => props.theme.primary};
    box-shadow: 0 0 5px ${props => props.theme.primary};
  }
`

const InputContainer = styled.label`
  margin: 0.35rem 0;
  width: 100%;
  & > div {
    font-weight: bold;
    ${props =>
      props.color
        ? css`
            color: ${props.theme.primary};
          `
        : css`
            color: ${props.theme.textLight};
          `}
  }
  ${props =>
    props.color &&
    css`
      ${InputStyled}, ${TextAreaStyled} {
        border: 1px solid ${props => props.theme.primary};
      }
    `}
`

export { InputContainer, InputStyled, TextAreaStyled }
