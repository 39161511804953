import styled from 'styled-components'
import GatsbyImg from 'gatsby-image'
import { Row, Col } from 'react-styled-flexboxgrid'
import hex2rgba from '../../utils/hex2rgba'

const SectionRowStyled = styled(Row)`
  overflow: hidden;
  position: relative;
  height: ${props => props.height};
  color: ${props => props.theme.textLight};
  font-size: 1.2rem;
`
const ContainerImg = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: ${props => hex2rgba(props.theme.primary, 0.6)};
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    /* left: 10%; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
`
const Img = styled(GatsbyImg)`
  left: 0;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  /* transform: translate(-50%, -50%); */
`
const SectionColStyled = styled(Col)`
  text-align: center;
  position: absolute;
  z-index: 10;
`

export { SectionRowStyled, SectionColStyled, Img, ContainerImg }
