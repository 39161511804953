import styled from 'styled-components'
import { BP_MEDIUM } from '../../styles/theme'
import { Col } from 'react-styled-flexboxgrid'

const TitleModal = styled.h2`
  font-size: 42px;
  margin: 0;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    font-size: 32px;
  }
`

const DescriptionModal = styled.span`
  fontsize: 28px;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    fontsize: 18px;
  }
`

const FooterModal = styled(Col)`
  margin-top: 2rem;
  font-size: 28px;
  text-align: center;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    font-size: 18px;
  }
`

export { TitleModal, DescriptionModal, FooterModal }
