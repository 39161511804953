import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 500px;
  align-items: flex-end;
`

export { Form }
