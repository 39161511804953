import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Link } from 'gatsby'
// import { Fade } from 'react-reveal'
import { push as Menu } from 'react-burger-menu'
import {
  Container,
  ContainerMenu,
  Content,
  SectionHeader,
  LanguageBar,
  SelectLabel,
  LanguageList,
  LanguageItem,
} from './Header.styled'
// import { LangContext } from '../../context'
import SelectLanguage from './SelectLanguage'
import ListItems from './ListItems'
import MenuItems from './MenuItems'

import logo_as_white from '../../images/logo_as_white.png'
import logo_sms_white from '../../images/logo-SMS-blanco.png'
import useGlobalReducer from '../Layout/GlobalContextProvider'

const Header = ({ location, color: initialColor }) => {
  // state = {
  // 	color: null,
  // 	open: false
  // };
  const [open, setOpen] = useState(false)
  const [color, setColor] = useState(null)

  const [{ lang }, dispatch] = useGlobalReducer()
  console.log('lang', lang)
  // componentDidMount() {
  // 	window.addEventListener('scroll', this.handleScroll, { passive: true });
  // }

  // componentDidUpdate(prevProps) {
  // 	if (this.props.location !== prevProps.location) {
  // 		this.setState({ open: false });
  // 	}
  // }

  // componentWillUnmount() {
  // 	window.removeEventListener('scroll', this.handleScroll);
  // }
  const handleScroll = () => {
    // const color = this.state.color;
    if (window.scrollY > 100 && !color) {
      setColor('color')
    } else if (window.scrollY < 100 && color) {
      setColor(null)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [color])

  useEffect(() => {
    if (open) setOpen(false)
  }, [location])

  const isMenuOpen = neValue => {
    setOpen(neValue.isOpen)
  }

  const toggleLanguage = newLang => {
    dispatch({ type: 'TOGGLE_LANGUAGE', payload: newLang })
    localStorage.setItem('lang', newLang)
  }

  return (
    <Container color={initialColor || color}>
      <SectionHeader center="xs" middle="xs">
        <Col xs={11}>
          <Content>
            <Row middle="xs" center="xs" style={{ color: 'white' }}>
              <Col lg={4}>
                <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={logo_as_white}
                    style={{ marginRight: '4px' }}
                    height={40}
                    alt="Logo AS"
                  />
                </Link>
              </Col>
              <Col
                lg={2}
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                }}
              >
                <span>Miembro</span>
              </Col>
              <Col lg={6}>
                <img src={logo_sms_white} height={40} alt="SMS Latinoamerica" />
              </Col>
            </Row>
            <ListItems color={color} location={location} />
            <SelectLanguage lang={lang} toggleLanguage={toggleLanguage} />
          </Content>
        </Col>
        {/* <Col
          xs={12}
          style={
            location && location.pathname === '/international-desk'
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <LanguageBar color={initialColor || color}>
            <SelectLabel>
              <b>Seleccione su idioma: </b> <br />
              <p>Select your language</p>
            </SelectLabel>
            <LanguageList>
              <LanguageItem to="/international-desk">Español</LanguageItem>
              <LanguageItem to="/international-desk">English</LanguageItem>
              <LanguageItem to="/international-desk">Portugués</LanguageItem>
              <LanguageItem to="/international-desk">中国</LanguageItem>
            </LanguageList>
          </LanguageBar>
        </Col> */}
      </SectionHeader>
      <ContainerMenu>
        <Menu
          isOpen={open}
          onStateChange={isMenuOpen}
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
        >
          <MenuItems color={color} location={location} />
        </Menu>
        <div style={{ position: 'fixed', right: '16px', top: '10px' }}>
          <SelectLanguage lang={lang} toggleLanguage={toggleLanguage} />
        </div>
      </ContainerMenu>
    </Container>
  )
}

export default Header
