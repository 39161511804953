import { createGlobalStyle } from 'styled-components'
import 'react-notifications/lib/notifications.css'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700"');

  :root {
    --card-shadow: 0px 4px 14px 0px rgba(0,0,0,0.1);
    --shadow-dark: 0 0.75rem 1rem rgba(0, 0, 0, 0.3);
    --shadow-light: 0.75rem 0.75rem 2rem rgba(0, 0, 0, 0.3);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;    
  }
  #root {
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
  }

  body {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    line-height: 1.6;
    color: #565656;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
`
