import React from 'react'
import {
  LinkIn,
  LinkOut,
  DropdownContent,
  Dropdown,
  LinkButton,
} from './Header.styled'
import { FormattedHTMLMessage } from 'react-intl'
import { itemsMenu } from './utils'

const ListItems = ({ location, color }) => {
  const renderLink = ({ out = false, linkTo, key }, index) => {
    if (!linkTo)
      return (
        <LinkButton>
          <FormattedHTMLMessage id={`header.${key}`} />
        </LinkButton>
      )
    if (out)
      return (
        <LinkOut key={index} href={linkTo} target="_blank">
          <FormattedHTMLMessage id={`header.${key}`} />
        </LinkOut>
      )
    return (
      <LinkIn key={index} to={linkTo}>
        <FormattedHTMLMessage id={`header.${key}`} />
      </LinkIn>
    )
  }

  return itemsMenu.slice(1).map((item, index) => {
    if (item.submenu) {
      return (
        <Dropdown key={index}>
          {renderLink(item)}
          <DropdownContent color={color}>
            {item.list.map(renderLink)}
          </DropdownContent>
        </Dropdown>
      )
    }
    return renderLink(item, index)
  })
  // <Fragment>
  //   <Dropdown>
  //     <LinkIn to="/nosotros/">
  //       <FormattedMessage id="header.aboutUs" />
  //     </LinkIn>
  //     <DropdownContent color={color}>
  //       <LinkIn to="/mision/">
  //         <FormattedMessage id="header.mision" />
  //       </LinkIn>
  //       <LinkIn to="/sms-mexico/">
  //         <FormattedMessage id="header.sms" />
  //       </LinkIn>
  //       <LinkIn to="/medios/">
  //         <FormattedMessage id="header.medios" />
  //       </LinkIn>
  //     </DropdownContent>
  //   </Dropdown>

  //   <Dropdown>
  //     <LinkIn to="/nuestro-grupo/">
  //       <FormattedMessage id="header.ourGroup" />
  //     </LinkIn>
  //     <DropdownContent color={color}>
  //       {listOurGroup.map(({ key, linkTo }) => (
  //         <LinkIn key={key} to={linkTo}>
  //           <FormattedHTMLMessage id={`header.${key}`} />
  //         </LinkIn>
  //       ))}
  //     </DropdownContent>
  //   </Dropdown>

  //   <LinkIn to="/#consulting-services" active={location && location.hash}>
  //     <FormattedMessage id="header.solutions" />
  //   </LinkIn>
  //   <LinkIn to="/international-desk">
  //     <FormattedMessage id="header.desk" />
  //   </LinkIn>
  //   <Dropdown>
  //     <LinkButton>
  //       <FormattedMessage id="header.asDigital" />
  //     </LinkButton>
  //     <DropdownContent color={color}>
  //       <LinkOut href="https://asxpert.com.mx/" target="_blank">
  //         <FormattedMessage id="header.asXpert" />
  //       </LinkOut>
  //       {/* Nueva seccion de asxpert */}
  //       <LinkOut href="http://ascg.uberprototech.com/login" target="_blank">
  //         <FormattedMessage id="header.asiViewer" />
  //       </LinkOut>
  //       <LinkOut href="https://asiconnect.com.mx/" target="_blank">
  //         <FormattedMessage id="header.asiConnect" />
  //       </LinkOut>
  //     </DropdownContent>
  //   </Dropdown>
  //   <LinkIn to="/nuestro-equipo/">
  //     <FormattedMessage id="header.directory" />
  //   </LinkIn>

  //   <LinkOut href="https://asnews.mx" target="_blank">
  //     <FormattedMessage id="header.blog" />
  //   </LinkOut>

  //   <LinkIn to="/contacto/">
  //     <FormattedMessage id="header.contact" />
  //   </LinkIn>
  // </Fragment>
}

export default ListItems
