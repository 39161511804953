import React from 'react'
import { CustomLink } from './Footer.styled'
import Icon from '../common/Icon'

const SocialLink = ({ icon, url }) => (
  <CustomLink href={url} target="_blank">
    <Icon icon={['fab', icon]} size="lg" />
  </CustomLink>
)

export default SocialLink
