import styled, { css } from 'styled-components'
import hex2rgba from '../../utils/hex2rgba'

export default styled.button`
  font-size: 1.4rem;
  font-family: 'Lato', 'sans-serif';
  font-weight: bold;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  user-select: none;
  height: 3.5rem;
  box-shadow: ${props => `${hex2rgba(
    props.theme.green,
    0.13
  )} 0px 1.6px 3.6px 0px,
    ${hex2rgba(props.theme.green, 0.11)} 0px 0.3px 0.9px 0px`};
  color: white;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: ${props => props.theme.green};
  text-decoration: none;
  ${props =>
    props.small &&
    css`
      font-size: 1.2rem;
      height: 2.5rem;
    `} &:hover {
    background: ${props => props.theme.greenDark};
  }
`
