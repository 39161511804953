export const BP_LARGEST = '75em' //       1200px  xl
export const BP_LARGE = '68.75em' //      1100px  lg
export const BP_MEDIUM = '56.25em' //     900px   md
export const BP_SMALL = '37.5em' //       600px   sm
export const BP_SMALLEST = '31.25em' //   500px   xs

const themeBase = {
  backgroundLight: '#ffffff', // light grey
  backgroundDark: '#909090', // grey
  backgroundBlueDark: '#003163',
  backgroundFooter: '#000F15',
  textLight: '#ffffff',
  danger: '#D30001', //red
  green: '#61D836', // green
  orange: '#F8BA00',
  greenDark: '#00AB00',
  red: '#AA052F',
}

const flexboxgrid = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
}

export default {
  primary: '#1D3971',
  primaryLight: '#00BEF0',
  primaryDark: '#002F77',
  ...themeBase,
  ...flexboxgrid,
}
