import styled from 'styled-components'
import {
  Accordion,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'

const CustomAccordion = styled(Accordion)`
  border: none;
`

const AccordionCustomItemTitle = styled(AccordionItemTitle)`
  background-color: transparent;
  color: ${props => props.theme.backgroundLight};
  padding: 0;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const AccordionCustomItemBody = styled(AccordionItemBody)`
  padding: 0 1rem;
  border: none;
`
export { CustomAccordion, AccordionCustomItemTitle, AccordionCustomItemBody }
