import React from 'react'
import { Row, Col } from 'react-styled-flexboxgrid'
import {
  ContainerFooter,
  SectionData,
  SectionDataItem,
  SectionCenter,
  LastSection,
  CustomTitle,
  CustomLink,
  InternalLink,
} from './Footer.styled'
import Icon from '../common/Icon'
import { FormattedMessage } from 'react-intl'
import smsLatamIcon from '../../images/sms_latam.png'
import logo_as_white from '../../images/logo_as_white.png'
import logo_sms_white from '../../images/logo-SMS-blanco.png'

import { socialLinks } from './utils'
import SocialLink from './SocialLink'

const Footer = () => (
  <ContainerFooter>
    <Row center="xs" middle="xs">
      <Col xs={11} lg={9}>
        <SectionData>
          <SectionDataItem>
            <Icon icon="map-marker-alt" size="lg" />
            <div>
              <div style={{ marginLeft: '1rem' }}>
                <CustomTitle>
                  <FormattedMessage id="footer.title1" />
                </CustomTitle>
                <span>
                  Obrero Mundial 644, Col. Atenor Salas, CDMX, 03010 México
                </span>
              </div>
              <div style={{ marginLeft: '1rem', marginTop: 12 }}>
                <span>
                  Calzada del Valle 255, Piso 2 Col. del Valle, San Pedro Garza
                  García, Monterrey, N.L. 66220 México
                </span>
              </div>
            </div>
          </SectionDataItem>
          <SectionDataItem>
            <Icon icon="at" size="lg" />
            <div style={{ marginLeft: '1rem' }}>
              <CustomTitle>
                <FormattedMessage id="footer.title2" />
              </CustomTitle>
              <CustomLink href="mailto:contacto@ascg.mx" noMargin>
                contacto@ascg.mx
              </CustomLink>
            </div>
          </SectionDataItem>
          <SectionDataItem>
            <Icon icon="phone" size="lg" />
            <div style={{ marginLeft: '1rem' }}>
              <CustomTitle>
                <FormattedMessage id="footer.title3" />
              </CustomTitle>
              <span>
                +52 55 5859 4873 <br />
                +52 55 5840 4611 <br />
                +52 55 5859 9296
              </span>
            </div>
          </SectionDataItem>
          <SectionDataItem>
            <Col>
              <img src={smsLatamIcon} alt="SMS Lationamerica" height={80} />
              <p style={{ color: '#A2A2A2', margin: 0 }}>
                <FormattedMessage id="aboutUs.sms.footer_image" />
              </p>
            </Col>
          </SectionDataItem>
        </SectionData>
        <SectionCenter>
          <Row middle="xs" center="xs" style={{ color: 'white' }}>
            <Col lg={4}>
              <img src={logo_as_white} height={30} alt="Logo AS" />
            </Col>
            <Col lg={2} style={{ textAlign: 'center', fontSize: '14px' }}>
              <span>Miembro</span>
            </Col>
            <Col lg={6}>
              <img src={logo_sms_white} height={35} alt="SMS Latinoamerica" />
            </Col>
          </Row>
          <div>
            {socialLinks.map(({ key, ...metaData }) => (
              <SocialLink key={key} {...metaData} />
            ))}
          </div>
        </SectionCenter>
        <LastSection>
          <span>
            COPYRIGHT © 2018-2024 AS CONSULTING GROUP -{' '}
            <InternalLink to="/aviso-de-privacidad/">
              Aviso de privacidad
            </InternalLink>
          </span>
        </LastSection>
      </Col>
    </Row>
  </ContainerFooter>
)

export default Footer
