import styled from 'styled-components'

const ContainerPage = styled.div`
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`

export { ContainerPage }
