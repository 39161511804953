import React from 'react'
import { Input, TextArea } from '../components/common/fields'

export default field => {
  switch (field.type) {
    case 'string':
      return <Input {...field} />
    case 'textarea':
      return <TextArea {...field} />
    default:
      return <div>Elemento inválido</div>
  }
}
