export const socialLinks = [
  {
    key: 'facebook',
    url: 'https://www.facebook.com/ASConsultingGlobal/',
    icon: 'facebook-f',
  },
  {
    key: 'instagram',
    url: 'https://www.instagram.com/ascgmx/',
    icon: 'instagram',
  },

  {
    key: 'twitter',
    url: 'https://twitter.com/ASCGMX',
    icon: 'twitter',
  },
  {
    key: 'linkedid',
    url: 'https://www.linkedin.com/company/asconsultores',
    icon: 'linkedin',
  },
  {
    key: 'youtube',
    url: 'https://www.youtube.com/channel/UCvT3RN0VFM7eAQA3mgrEcjw',
    icon: 'youtube',
  },
]
