import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { NotificationContainer } from 'react-notifications'

import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import es from 'react-intl/locale-data/es'
import zh from 'react-intl/locale-data/zh'
import { addLocaleData, IntlProvider } from 'react-intl'
import localEng from '../../../data/en.json'
import localPt from '../../../data/pt.json'
import localEs from '../../../data/es.json'
import localZh from '../../../data/zh.json'

import Header from '../Header'
import Footer from '../Footer'
import ModalSubscription from '../subscription/ModalSubscription'

import theme from '../../styles/theme'
import GlobalStyle from '../../styles/global'
import { ContainerPage } from './Layout.styled'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCheckCircle,
  faMapMarkerAlt,
  faAt,
  faPhone,
  faBuilding,
  faHome,
  faUsers,
  faConciergeBell,
  faComments,
  faUserCircle,
  faScroll,
  faMobileAlt,
  faTimes,
  faUniversity,
  faCogs,
  faUserPlus,
  faCalendarCheck,
  faPaste,
  faChevronRight,
  faGlobeAmericas,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons'
// import Provider from './Provider'
// import { LangContext } from '../../context'
import SEO from '../SEO'
import useGlobalReducer from './GlobalContextProvider'

addLocaleData([...es, ...en, ...pt, ...zh])

library.add(
  fab,
  faCheckCircle,
  faChevronRight,
  faMapMarkerAlt,
  faAt,
  faPhone,
  faBuilding,
  faHome,
  faUsers,
  faConciergeBell,
  faComments,
  faUserCircle,
  faScroll,
  faMobileAlt,
  faTimes,
  faUniversity,
  faCogs,
  faUserPlus,
  faCalendarCheck,
  faPaste,
  faGlobeAmericas,
  faLayerGroup
)

const messages = {
  en: localEng,
  pt: localPt,
  es: localEs,
  zh: localZh,
}
console.log('messages', messages)

const Layout = ({ children, ...props }) => {
  const [{ lang }, dispatch] = useGlobalReducer()
  function flattenObject(ob) {
    var toReturn = {}
    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue

      if (typeof ob[i] === 'object') {
        var flatObject = flattenObject(ob[i])
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue

          toReturn[i + '.' + x] = flatObject[x]
        }
      } else {
        toReturn[i] = ob[i]
      }
    }
    return toReturn
  }

  React.useEffect(() => {
    const localLang = localStorage.getItem('lang')
    if (localLang) {
      dispatch({ type: 'TOGGLE_LANGUAGE', payload: localLang })
      // this.props.handleLanguage(localLang)
    } else {
      dispatch({
        type: 'TOGGLE_LANGUAGE',
        payload: navigator.language.split('-')[0],
      })

      // this.props.handleLanguage(navigator.language.split('-')[0])
    }
  }, [])

  return (
    <IntlProvider locale={lang} messages={flattenObject(messages[lang])}>
      <ThemeProvider theme={theme}>
        <div id="outer-container">
          <SEO {...props} />
          <GlobalStyle />
          <Header location={props.location} color={props.color} />
          <div id="page-wrap">
            <ContainerPage>{children}</ContainerPage>
            <Footer />
          </div>
          <NotificationContainer />
        </div>
        {/* <ModalSubscription /> */}
      </ThemeProvider>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
