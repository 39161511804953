import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { BP_MEDIUM } from '../../styles/theme'

const ContainerFooter = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: ${props => props.theme.backgroundFooter};
`

const SectionData = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1rem 0;
  color: ${props => props.theme.textLight};

  @media only screen and (max-width: ${BP_MEDIUM}) {
    flex-direction: column;
  }
`
const SectionDataItem = styled.div`
  max-width: 250px;
  display: flex;
  padding: 0.75rem;
  text-align: left;
`
const CustomTitle = styled.h3`
  text-transform: uppercase;
  margin: 0;
`
const CustomLink = styled.a`
  text-decoration: none;
  margin: 0 1rem;
  color: ${props => props.theme.textLight};
  &:hover {
    color: ${props => props.theme.primaryLight};
  }
  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `};
`
const InternalLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primaryLight};
  &:hover {
    color: ${props => props.theme.textLight};
  }
`

const SectionCenter = styled(SectionData)`
  align-items: center;
  padding-bottom: 2rem;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    flex-direction: column-reverse;
    & > img {
      height: 40px;
      margin: 1rem 0;
    }
  }
`

const LastSection = styled(SectionData)`
  position: absolute;
  font-size: 0.785rem;
  text-transform: uppercase;
  padding: 4px 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    align-items: center;
  }
`

export {
  ContainerFooter,
  SectionData,
  SectionCenter,
  LastSection,
  SectionDataItem,
  CustomTitle,
  CustomLink,
  InternalLink,
}
