import React from 'react'
import {
  SectionRowStyled,
  SectionColStyled,
  Img,
  ContainerImg,
} from './SectionImagen.styled'

const SectionImage = ({ children, height = '100vh', ...imageProps }) => (
  <SectionRowStyled center="xs" middle="xs" height={height}>
    <ContainerImg>
      <Img {...imageProps} />
    </ContainerImg>
    <SectionColStyled xs={11} lg={8}>
      {children}
    </SectionColStyled>
  </SectionRowStyled>
)

export default SectionImage
