import React, { Fragment } from 'react'
import { LinkIn, LinkOut, ContainerIconMenuItem } from './Header.styled'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Icon from '../common/Icon'

import { itemsMenu, handleOpenMenu } from './utils'
import { AccordionItem } from 'react-accessible-accordion'

import {
  CustomAccordion,
  AccordionCustomItemTitle,
  AccordionCustomItemBody,
} from './Accordion.styled'

const MenuItems = ({ location, color }) =>
  itemsMenu.map((item, index) => {
    if (item.submenu) {
      const keyMenu = handleOpenMenu(location && location.pathname)
      return (
        <CustomAccordion key={index}>
          <AccordionItem expanded={item.key === keyMenu}>
            <AccordionCustomItemTitle>
              {!item.linkTo ? (
                <div style={{ padding: '1.5rem 0' }}>
                  {item.icon ? (
                    <ContainerIconMenuItem>
                      <Icon icon={item.icon} size="lg" />
                    </ContainerIconMenuItem>
                  ) : null}
                  <FormattedHTMLMessage id={`header.${item.key}`} />
                </div>
              ) : (
                <LinkIn to={item.linkTo} key={index}>
                  {item.icon ? (
                    <ContainerIconMenuItem>
                      <Icon icon={item.icon} size="lg" />
                    </ContainerIconMenuItem>
                  ) : null}
                  <FormattedHTMLMessage id={`header.${item.key}`} />
                </LinkIn>
              )}
              <div className="accordion__arrow" />
            </AccordionCustomItemTitle>
            <AccordionCustomItemBody>
              <CustomAccordion>
                {item.list.map((item, index) => (
                  <AccordionItem key={index}>
                    <AccordionCustomItemTitle>
                      {item.out ? (
                        <LinkOut href={item.linkTo} key={index} target="_blank">
                          {item.icon ? (
                            <ContainerIconMenuItem>
                              <Icon icon={item.icon} size="xs" />
                            </ContainerIconMenuItem>
                          ) : null}
                          <FormattedHTMLMessage id={`header.${item.key}`} />
                        </LinkOut>
                      ) : (
                        <LinkIn to={item.linkTo} key={index}>
                          {item.icon ? (
                            <ContainerIconMenuItem>
                              <Icon icon={item.icon} size="xs" />
                            </ContainerIconMenuItem>
                          ) : null}
                          <FormattedHTMLMessage id={`header.${item.key}`} />
                        </LinkIn>
                      )}
                    </AccordionCustomItemTitle>
                  </AccordionItem>
                ))}
              </CustomAccordion>
            </AccordionCustomItemBody>
          </AccordionItem>
        </CustomAccordion>
      )
    }
    if (item.out) {
      return (
        <LinkOut href={item.linkTo} key={index} target="_blank">
          {item.icon ? (
            <ContainerIconMenuItem>
              <Icon icon={item.icon} size="xs" />
            </ContainerIconMenuItem>
          ) : null}
          <FormattedHTMLMessage id={`header.${item.key}`} />
        </LinkOut>
      )
    }

    return (
      <LinkIn
        to={item.linkTo}
        key={index}
        active={
          location && item.linkTo === '/#consulting-services' && !!location.hash
            ? location.hash
            : ''
        }
      >
        <ContainerIconMenuItem>
          <Icon icon={item.icon} size="lg" />
        </ContainerIconMenuItem>
        <FormattedMessage id={`header.${item.key}`} />
      </LinkIn>
    )
  })

export default MenuItems
