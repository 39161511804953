import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputContainer, InputStyled } from './field.styled'
import { FormattedMessage } from 'react-intl'

export default class Input extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    order: PropTypes.array,
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    validation: PropTypes.array,
    options: PropTypes.array,
    errors: PropTypes.object,
    fields: PropTypes.array,
    element: PropTypes.string,
  }

  static defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    type: 'text',
    name: 'input',
    label: 'Input',
    required: false,
    autoComplete: 'off',
  }
  handleOnChange = e => {
    const { name, value } = e.target
    const { onChange, options } = this.props
    onChange(value, name, options)
  }

  render() {
    const { props } = this
    return (
      <InputContainer color={props.color}>
        <div>
          <FormattedMessage id={`element.input_label.${props.name}`} />
          <span style={{ color: 'red' }}>{props.required && '*'}</span>
        </div>
        <InputStyled
          autoComplete="off"
          name={props.name}
          type={props.type === 'string' ? 'text' : props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={this.handleOnChange}
          disabled={props.disabled}
        />
        {props.errors && props.errors[props.name] && (
          <span style={{ color: 'red', fontWeight: '300' }}>
            {props.errors[props.name]}
          </span>
        )}
      </InputContainer>
    )
  }
}
