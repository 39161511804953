import _ from 'lodash'

export const validateFields = (fields, requiredFields) => {
  let regex = ''
  let errors = {}
  _.map(requiredFields, ({ name }) => {
    switch (name) {
      case 'email':
        regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regex.test(fields[name])) errors[name] = 'El correo no es valido'
        break
      //   case 'phone_number':
      //     if (!isValidNumber(fields[key], 'MX'))
      //       errors[key] = 'El teléfono no es valio'
      //     break
      default:
        if (!fields[name]) errors[name] = 'Este campo es requerido'
    }
  })
  return errors
}
